import React from 'react'
import ReCAPTCHA from 'reaptcha'

const RecaptBox = ({fieldClass, captRef, /*handleonError, handleonExpire,*/ handleonVerify}) => (
  <div className="">
      <div className={fieldClass}>
			<ReCAPTCHA
				data-netlify-recaptcha="true" 
				//onError={handleonError}
				//onExpire={handleonExpire}
				onVerify={handleonVerify}
				ref={captRef}
				size="invisible"
				sitekey={process.env.GATSBY_RECAPTCHA_KEY} //
			/>
      </div>
  </div>
);

export default RecaptBox